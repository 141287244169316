import React from 'react';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Container } from '../components/lib';

const NotFoundPage = ({ className }) => (
  <Layout>
    <SEO title='404: Not found' />
    <Container className={className}>
      <h1 className='bold'>404</h1>
      <h4>Sorry, that page doesn't exist!</h4>
    </Container>
  </Layout>
);

const StyledNotFoundPage = styled(NotFoundPage)`
  padding-top: 5rem;

  @media (min-width: 500px) {
    padding-top: 10rem;
  }
`;

export default StyledNotFoundPage;
